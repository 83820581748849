<ng-container *ngIf="productDetails$ | async as product">
	<div class="description-heading">{{"productDetail.description" | cxTranslate}}</div>
	<div class="mb-4">{{product?.description}}</div>
	<ng-container *ngIf="productOption$ | async as options">
		<ul class="property-list">
			<!-- CATEGORY -->
			<li *ngIf="product?.categories?.length > 0" class="property-list-item">
				<div class="row custom-align-top">
					<div class="col-2">
						<img class="description-icon" src="assets/images_and_icons/SG-icons-category-purple.png" alt="category">
					</div>
					<div class="col-5">
						<span class="align-middle">{{"productDetail.category" | cxTranslate}}</span>
					</div>
					<div class="col-5">
						<ul class="list-unstyled">
							<li *ngFor="let ctgry of product?.categories">{{ctgry.name}}</li>
						</ul>
					</div>
				</div>
			</li>
			<!-- COMPATIBILITY -->
			<li *ngIf="options?.compatibility?.length > 0" class="property-list-item">
				<div class="row custom-align-top">
					<div class="col-2">
						<img class="description-icon" src="assets/images_and_icons/SG-icons-compatibility_onshore-purple.png" alt="category">
					</div>
					<div class="col-5">
						<span class="align-middle">{{"productDetail.compatability" | cxTranslate}}</span>
					</div>
					<div class="col-5">
						<ul class="list-unstyled">
							<li *ngFor="let ctgry of options.compatibility">{{ctgry}}</li>
						</ul>
					</div>
				</div>
			</li>
			<!-- TECHNOLOGY -->
			<li *ngIf="options?.technology?.length > 0" class="property-list-item">
				<div class="row custom-align-top">
					<div class="col-2">
						<img class="description-icon" src="assets/images_and_icons/SG-icons-technology-purple.png" alt="category">
					</div>
					<div class="col-5">
						<span class="align-middle">{{"productDetail.technology" | cxTranslate}}</span>
					</div>
					<div class="col-5">
						<ul class="list-unstyled">
							<li *ngFor="let tech of options.technology">{{tech}}</li>
						</ul>
					</div>
				</div>
			</li>
			<!-- AVAILABILITY -->
			<li *ngIf="product?.viewProductAvailability && options?.quotationFlag === false && options?.stock?.estimatedLeadTime && product?.sourceSapSystem?.includes('SR')"
				class="property-list-item">
				<div class="row custom-align-top">
					<div class="col-2">
						<img class="description-icon" src="assets/images_and_icons/SG-icons-Availability-purple.png" alt="category">
					</div>
					<div class="col-5">
						<span class="align-middle">{{"productDetail.availability" | cxTranslate}}</span>
					</div>
					<div class="col-5">
						<ul class="list-unstyled">
							<li>{{"productDetail.available" | cxTranslate}}</li>
						</ul>
					</div>
				</div>
			</li>
			<li *ngIf="product?.viewProductAvailability && options?.quotationFlag === false && options?.stock?.estimatedLeadTime && !product?.sourceSapSystem.includes('SR') && product?.stock?.marketingStrategy"
				class="property-list-item">
				<div class="row custom-align-top">
					<div class="col-2">
						<img class="description-icon" src="assets/images_and_icons/SG-icons-Availability-purple.png" alt="category">
					</div>
					<div class="col-5">
						<span class="align-middle">{{"productDetail.availability" | cxTranslate}}</span>
					</div>
					<div class="col-5">
						<ul class="list-unstyled">
							<li>
								{{product.stock.marketingStrategy === 'PULL' ? 'Available' : 'Immediately Available'}}
							</li>
						</ul>
					</div>
				</div>
			</li>
			<!-- ESTIMATED LEAD TIME -->
			<li *ngIf="options?.obsoleteFlag === false && options?.quotationFlag === false &&
             options?.stock?.estimatedLeadTime > 0" class="property-list-item">
				<div class="row custom-align-top">
					<div class="col-2">
						<img class="description-icon" src="assets/images_and_icons/SG-icons-estimated-lead-time-purple.png" alt="category">
					</div>
					<div class="col-5">
						<span class="align-middle">
							{{"productDetail.estimatedLeadTime" | cxTranslate}}
						</span>
					</div>
					<div class="col-5">
						<ul class="list-unstyled">
							<li>{{options.stock.estimatedLeadTime}} {{"productDetail.days" | cxTranslate}}</li>
						</ul>
					</div>
				</div>
			</li>
			<!-- DIMENSIONS -->
			<li *ngIf="checkDimensions(options)" class="property-list-item">
				<div class="row custom-align-top">
					<div class="col-2">
						<img class="description-icon" src="assets/images_and_icons/SG-icons-dimensions-purple.png" alt="category">
					</div>
					<div class="col-5">
						<span class="align-middle">{{"productDetail.dimensions" | cxTranslate}}</span>
					</div>
					<div class="col-5">
						<ul class="list-unstyled">
							<li *ngIf="options?.length">
								{{"productDetail.length" | cxTranslate}}- {{options.length}}
								{{options.lengthUnitCode ? options.lengthUnitCode : 'CM'}}
							</li>
							<li *ngIf="options?.width">
								{{"productDetail.width" | cxTranslate}}- {{options.width}}
								{{options.widthUnitCode ? options.widthUnitCode : 'CM'}}
							</li>
							<li *ngIf="options?.height">
								{{"productDetail.height" | cxTranslate}}- {{options.height}}
								{{options.heightUnitCode ? options.heightUnitCode : 'CM'}}
							</li>
							<li *ngIf="options?.volume">
								{{"productDetail.volume" | cxTranslate}}- {{options.volume}}
								{{options.volumeUnitCode ? options.volumeUnitCode : 'CDM'}}
							</li>
						</ul>
					</div>
				</div>
			</li>
			<!-- WEIGHT -->
			<li *ngIf="options?.grossWeight || options?.netWeight" class="property-list-item">
				<div class="row custom-align-top">
					<div class="col-2">
						<img class="description-icon" src="assets/images_and_icons/SG-icons-weight-purple.png" alt="category">
					</div>
					<div class="col-5">
						<span class="align-middle">{{"productDetail.weight" | cxTranslate}}</span>
					</div>
					<div class="col-5">
						<ul class="list-unstyled">
							<li *ngIf="options?.grossWeight">
								{{"productDetail.grossweigth" | cxTranslate}}- {{options.grossWeight}}
								{{options.grossWeightUnitCode ? options.grossWeightUnitCode : 'KG' }}
							</li>
							<li *ngIf="options?.netWeight">
								{{"productDetail.netweight" | cxTranslate}}- {{options.netWeight}}
								{{options.netWeightUnitCode ? options.netWeightUnitCode : 'KG' }}
							</li>
						</ul>
					</div>
				</div>
			</li>
		</ul>
	</ng-container>
</ng-container>